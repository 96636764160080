import React from 'react'
import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import Hero from '../components/Hero';
import FullPageLayout from '../components/layout/FullPageLayout';
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import Testimonials from "../components/Testimonials";
import LandingHeader from "../components/landing-header";
import ContactForm from "../components/ContactForm";
import InstantForm from "../components/InstantForm";
import HomeProjects from "../components/HomeProjects";

export const ServiceTemplate = ({
                                    title,
                                    subtitle,
                                    servicesExperience,
                                    image,
                                    testimonials,
                                    projects
                                }) => {

    return (
        <div>
            <div className="l-component">
                <Hero title={title}
                      subheading={subtitle}>
                    <PreviewCompatibleImage imageInfo={image} />
                </Hero>

                <div className={"l-component"}>
                    <section className={"c-hero-features"}>
                        <header>
                            <span>Services / Experience</span>

                            <h2 className={"copy-header copy-header--l"}>{servicesExperience.title}</h2>

                            <p className={"copy"}>
                                {servicesExperience.subtitle}
                            </p>
                        </header>

                        <div>
                            {servicesExperience.items.map((item, i) => (
                                <article key={i}>
                                    <div>
                                        <header>{item.title}</header>

                                        <p>
                                            {item.description}
                                        </p>
                                    </div>
                                </article>
                            ))}
                        </div>
                    </section>
                </div>

                {/*Todo:
                - Enhance text heavy stuff
                - Enhance mobile
                -Code samples?
                */}

                {/*    Projects/work*/}
                <section className={"l-component p-landing--recent-work"}>
                    <LandingHeader label={""} title={"Recent projects"} />

                    <HomeProjects projects={projects} />
                </section>

                <section className={"l-component p-landing--testimonials"}>
                    <LandingHeader label={"Testimonials / References"} title={"Comments from previous customers"} />

                    <Testimonials testimonials={testimonials} />
                </section>

                <section className={"l-component p-landing--cta"}>
                    <span className={"copy-subheader"}>Short for time? Drop your email here:</span>
                    <InstantForm context={"Laravel"}/>
                    <span className={"copy-subheader"}>and I'll be in touch</span>
                </section>

                <section className={"l-component p-landing--contact"}>
                    <LandingHeader label={"Interested?"}
                                   title={"Get in touch"} />

                    <ContactForm/>
                </section>
            </div>
        </div>
    )
}

ServiceTemplate.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    image: PropTypes.object,
    testimonials: PropTypes.array,
}

const ServicePost = ({ data }) => {
    const post = data.page;
    const testimonials = data.testimonials.edges.map((t) => t.node.frontmatter);
    const projects = data.projects.edges.map((p) => p.node.frontmatter);

    return (
        <FullPageLayout pageName={"landing"} title={post.frontmatter.title}>
            <ServiceTemplate
                title={post.frontmatter.title}
                subtitle={post.frontmatter.subtitle}
                servicesExperience={{
                    title: post.frontmatter.servicesTitle,
                    subtitle: post.frontmatter.servicesSubtitle,
                    items: post.frontmatter.ServicesExperience_
                }}
                image={post.frontmatter.heroImage}
                testimonials={testimonials}
                projects={projects}
            />
        </FullPageLayout>
    )
}

ServicePost.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.object,
    }),
}

export default ServicePost

export const pageQuery = graphql`
    query LandingPostByID($id: String!) {
      page: markdownRemark(id: {eq: $id}) {
        id
        html
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          servicesTitle
          servicesSubtitle
          ServicesExperience_ {
            title
            description
          }
          heroImage {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
            extension
            publicURL
          }
          subtitle
        }
      }
      testimonials: allMarkdownRemark(limit: 6, filter: {fields: {slug: {regex: "^/testimonials/"}}}) {
        edges {
          node {
            frontmatter {
              quote
              author
              image: heroImage {
                publicURL
              }
            }
          }
        }
      }
      projects: allMarkdownRemark(limit: 3, filter: {fields: {slug: {regex: "^/recent-work/"}}}) {
        edges {
          node {
            frontmatter {
             title
              description
              objective
              tech
              Images {
                image {
                  publicURL
                  ext
                  childImageSharp {
                      fluid(maxWidth: 2048, quality: 80) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                }
              }
              date
            }
          }
        }
      }
    }
`
