import React from 'react'
import PropTypes from "prop-types";
import Button from "./Button";
import {scrollToEle} from "../lib/scroll";

const Template = ({children, title, subheading}) => (
   <div>
       <div className="c-hero l-flex l-flex--space-between">
           <div>
               <h1 className="copy-header copy-header--huge">
                   {title}
               </h1>

               <div className="c-hero--mobile-image">
                   {children}
               </div>

               <h2 className="copy-subheader">
                   {subheading}
               </h2>

               <div className="c-hero--buttons">
                   <Button link={"/work"}
                           click={() => scrollToEle('.c-contact-form', 300)}
                           type="orange">
                       <span>Get in touch</span>
                   </Button>

                   <Button link={"/test"}
                           click={() => scrollToEle('.c-hero-features', 0)}
                           title="Todo - offer two opts on hover">
                       <span>More info</span>
                   </Button>
               </div>
           </div>

           <div>
               {children}
           </div>
       </div>
   </div>
);

const Hero = ({children, title, subheading}) => {
    return (<Template children={children}
                      title={title}
                      subheading={subheading}></Template>);
}

Hero.propTypes = {
    children: PropTypes.any,
    title: PropTypes.string,
    subheading: PropTypes.string
}

export default Hero
