import React from 'react'
import PropTypes from "prop-types";

const LandingHeader = ({label, title, description}) => {
    const desc = description ? (<p className="copy">{description}</p>) : '';

    return (
        <header className={"c-landing-header"}>
            <span className={"copy-subheader copy-subheader--small copy-subheader--tertiary copy-subheader-secondary"}>{label}</span>
            <h2 className="copy-header copy-header--l">{title}</h2>
            {desc}
        </header>
    );
}

LandingHeader.propTypes = {
    children: PropTypes.any,
    description: PropTypes.string,
    title: PropTypes.string,
}

export default LandingHeader
