import React, {useState} from "react";
import Input from "./Input";
import Button from "./Button";
import PropTypes from 'prop-types'
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const InstantForm = ({context}) => {

    const [showButton, setShowButton] = useState(false);
    const [email, setEmail] = useState('');

    const toggleButtonDisplay = (e) => {
        const show = e.target.value !== '';

        setEmail(e.target.value);

        setShowButton(show);
    };


    return (
        <form className={"c-instant-form l-flex l-flex--column"}
              name="instant"
              data-netlify="true"
              data-netlify-honeypot="h-name"
              action="/contact/thanks"
              method="post">
            <FontAwesomeIcon icon={faEnvelope}  size="s" />

            <Input name={"email"}
                   placeholder={"E-mail"}
                   value={email}
                   onChange={toggleButtonDisplay}
                   type={"email"} />

            <Input name={"context"}
                   value={context}
                   type={"hidden"} />

            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <input type="hidden"
                   name="form-name"
                   value="instant" />

            <input type="hidden"
                   name="h-name" />

            <span className={"c-instant-form--btn"} data-display={showButton}>
                <Button type={"form"}>Go</Button>
            </span>
        </form>
    );
};

InstantForm.propTypes = {
    context: PropTypes.string
}

export default InstantForm;
