import React from 'react'
import { Helmet } from 'react-helmet'
import Navbar from './../Navbar';
import './../../styles/all.scss'
import PropTypes from "prop-types";
import {useGlobalState} from "./../../store/app";
import useSiteMetadata from "../SiteMetadata";
import Footer from '../Footer';

const TemplateWrapper = ({ pageName, title = null, children }) => {
  const [navOpen, setNavOpen] = useGlobalState('navOpen');
  const { defaultTitle, description } = useSiteMetadata();
  const metaTitle = title ? title : defaultTitle;

  const navBar = React.createRef();

  const handleNavBarOpen = (open) => {
      setNavOpen(open);
  };

  return (
      <div className={`p-full-page p-${pageName}`}>
          <Helmet>
              <link rel="preconnect" href="https://fonts.gstatic.com/" crossOrigin />
              <meta name="viewport" content="width=device-width,initial-scale=1.0,minimum-scale=1"/>
              <meta name="description" content={description}/>
              <title>{metaTitle}</title>
          </Helmet>

          <Navbar onToggle={handleNavBarOpen} ref={navBar} />

          <main>
              {children}
          </main>

          <Footer />
      </div>
  )
}

TemplateWrapper.propTypes = {
    pageName: PropTypes.string
}

export default TemplateWrapper
